import styled from 'styled-components';

const Progress = styled.div`
    position: absolute;
    top: -55px;
    background: #694fff;
    padding: 7px 10px;
    border: 6px solid #000;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;

    @media(min-width: 1650px) {
        top: -68px;
        padding: 10px;
        margin-left: 1.5%;
    }

    @media(max-width: 500px) {
        top: -49px;
        border-width: 5px;
        padding: 7px;
    }

    .text {
        color: #fff;
        font-family: var(--font-montserrat);
        font-size: 13px;
        display:block;
        text-align:right;
        padding-bottom: 6px;

        @media(min-width: 1650px) {
            font-size: 14px;
            padding-bottom: 8px;
        }

        @media(max-width: 500px) {
            font-size: 11px;
            padding-bottom: 5px;
        }
    }
    
    .progressbar-inner {
        background-color: #5cc7fd;
        width: 300px;
        height: 16px;
        border-radius: 2px;
        overflow: hidden;
        position: relative;

        @media(min-width: 1650px) { 
            width: 390px;
            height: 20px;
        }

        @media(max-width: 500px) {
            width: 80px;
            height: 14px;
        }

        var {
            font-family: var(--font-montserrat);
            font-size: 11px;
            font-weight: 600;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            color: #694fff;
            font-style:normal;

            @media(min-width: 1650px) {
                font-size: 12px;
            }
        }

        .bar {
            height:100%;
            background-color: #aeffff;
        }
    }
`;

export const ProgressBar = function({available, redeemed}) {

    var available = available;
    var minted = redeemed;
 
    var progressWidth = (minted / available * 100) + "%";
    var percent = parseFloat(minted / available * 100).toFixed(1);

    return <Progress>
            <div className='text'>Minted: <b>{redeemed}</b></div> 
            <div className="progressbar-inner"><div className='bar' style={{width: progressWidth}}><var>{percent}%</var></div></div>
        </Progress>
}

 