// import { Paper } from '@material-ui/core';
import Countdown from 'react-countdown';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import { useState } from 'react';


import { MintButton } from './MintButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0),
      '& > *': {
        margin: theme.spacing(0.5),
        marginRight: 0,
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#384457',
        color: 'white',
        borderRadius: 5,
        fontSize: 10,
      },
    },
    done: {
      display: 'flex',
      margin: theme.spacing(1),
      marginRight: 0,
      padding: theme.spacing(1),
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#384457',
      color: 'white',
      borderRadius: 5,
      fontWeight: 'bold',
      fontSize: 18,
    },
    item: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    expired: {
      width: "120px",
      flex: "none !important"
    }
  }),
);

interface MintCountdownProps {
  date: Date | undefined;
  style?: React.CSSProperties;
  status?: string;
  onComplete?: () => void;

  candyMachine : any;
  isMinting: any;
  onMint: any;
}

interface MintCountdownRender {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}


// export const CountDownFunc = function(date: any) {
//   const [distance, setDistance] = useState(0);
//   const [month, setMonth] = useState(0);
//   const [days, setDays] = useState(0);
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
  
//   var countDownDate = new Date(date).getTime();

// // Update the count down every 1 second
// var x = setInterval(function () {
//   // Get today's date and time
//   var now = new Date().getTime();

//   // Find the distance between now and the count down date
//   setDistance(countDownDate - now);

//   // Time calculations for days, hours, minutes and seconds
//   setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
//   if (days > 30) {
//     setMonth(Math.floor(days / 30));
//   }
//   setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
//   setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
//   setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

  
//   // If the count down is finished, write some text
//   if (distance < 0) {
//     clearInterval(x);
//   }
// }, 1000);

// const classes = useStyles();
// if(date) {
//     return (
//       <>
//       {distance < 0 ? 
//         <span className={classes.expired}>EXPIRED</span>
//       :
//       <Countdown
//         date={date}
//         onComplete={onComplete}
//         renderer={renderCountdown}
//       />
//       }
//       </>
//     )
//   }
//   else {
//     return null;
//   }
// }


export const MintCountdown: React.FC<MintCountdownProps> = ({
  date,
  status,
  style,
  onComplete,
  candyMachine,
  isMinting,
  onMint
}) => {
  const classes = useStyles();
  const renderCountdown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: MintCountdownRender) => {
    // hours += days * 24;
    if (completed) {
      //  return status ? <span className={classes.done}>{status}</span> : null;
    return (
      <MintButton
        candyMachine={candyMachine}
        isMinting={isMinting}
        onMint={onMint}
      />
      )
    } else {
      return (
          <>
            <span className={classes.item }>
              <em>{days < 10 ? `0${days}` : days}</em> DAYS
            </span>

            <span className={classes.item }>
              <em>{hours < 10 ? `0${hours}` : hours}</em> HOURS
            </span>
          
            <span className={classes.item}>
              <em>{minutes < 10 ? `0${minutes}` : minutes}</em> MINUTES
            </span>
          
          
            <span className={classes.item}>
              <em>{seconds < 10 ? `0${seconds}` : seconds}</em> SECONDS
            </span>
            </>
      );
    }
  };

  if (date) {
    return (      
      <Countdown
        date={date}
        onComplete={onComplete}
        renderer={renderCountdown}
      />
    );
  } else {
    return null;
  }
};